import React, { useState, useEffect } from 'react';
import './Banner.css';
import { Menu } from 'antd';
import { Link} from 'react-router-dom';

const { SubMenu } = Menu;

function renderSubMenuItems2(items) {

  return items.map((item) => {
    if (item.items && item.items.length > 0) {
      return (
        <SubMenu className='skin-care-item' key={item.key} title={<Link className='menu-link' to={`/collections`}>{item.label}</Link>}>
          {renderSubMenuItems2(item.items)}
        </SubMenu>
      );
    }
    return <Menu.Item key={item.key} className='skin-care-item'>
             <Link className='menu-link' to={`/collections`}>{item.label}</Link>
          </Menu.Item>;
  });
}

function renderSubMenuItems(items) {
  return items.map((item) => {
    if (item.items && item.items.length > 0) {
      return (
        <SubMenu className='skin-care-item' key={item.key} title={<Link className='menu-link' to={`/collections`}>{item.label}</Link>}>
          {renderSubMenuItems2(item.items)}
        </SubMenu>
      );
    }
    return <Menu.Item key={item.key} className='skin-care-item'>
              {/* <Link to={`/collections/${item.key}`}>{item.label}</Link> */}
              <Link className='menu-link' to={`/collections`}>{item.label}</Link>
           </Menu.Item>;
  });
}

function renderMenuItems(items) {
  return items.map((item) => {
    if (item.items && item.items.length > 0) {
      return (
        <SubMenu key={item.key} title={item.label}>
          {renderSubMenuItems(item.items)}
        </SubMenu>
      );
    }
    return <Menu.Item key={item.key}>{item.label}
              <Link className='menu-link' to={`/collections/${item.key}`}></Link>
          </Menu.Item>;
  });
}

function Header() {

  const[data, setData] = useState([]);

  useEffect(() => {
    function fetchCategory() {
      return fetch('http://localhost:8081/GetAllCategory').then(response => response.json())
      .then(categoryData => {
        setData(prevData => {
        const newData = [...prevData];

        // Check if the category already exists in the data array
        const existingCategory = newData.find(item => item.key === categoryData.key);
        if (!existingCategory) {
          newData.push(categoryData);
        }

        return newData;
      });
      }).catch(error => {
        console.error(error);
      })
    }

    function fetchBrand() {
      return fetch('http://localhost:8081/GetAllBrand').then(response => response.json())
      .then(brandData => {
        setData(prevData => {
          const newData = [...prevData];

          // Check if the brand already exists in the data array
          const existingBrand = newData.find(item => item.key === brandData.key);
          if (!existingBrand) {
            newData.push(brandData);
          }

          return newData;
        });
      }).catch(error => {
        console.error(error);
      })
    }

    fetchCategory()
      .then(() => fetchBrand())
      .then(() => {
        console.log(data); // Data from both requests is appended to the data array
      })
      .catch(error => {
        console.error(error);
      });
  }, [])

  return (
    <div>
      <div className="jumbotron">
        <h1 className="display-3 header-font">GLOWTHEA</h1>
      </div>

        <Menu className="custom-menu"mode="horizontal" >
          {renderMenuItems(data)}
        </Menu>

    </div>
  );
}

export default Header;
