import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Details from './pages/Details';
import Home from './pages/Home'
import Collections from './pages/Collections';

function App() {
  return (
    <Router>
      <div className='App'>
        <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/collections" element={<Collections />} />
            <Route path="/details/:key" element={<Details />} />
        </Routes>
      </div>    
    </Router>
  );
}

export default App;
