import React, { useState, useEffect } from 'react'
import './CollectionsCatalog.css'
import { LaptopOutlined, NotificationOutlined, UserOutlined } from '@ant-design/icons';
import { Select, Card, Col, Row  , Layout, Menu} from 'antd';
import { Link} from 'react-router-dom';
const { Header, Content, Footer, Sider } = Layout;
const { SubMenu } = Menu;

function CollectionsCatalog(){

    const[dataCategory, setCategory] = useState([]);
    const[dataBrand, setBrand] = useState([]);
    
    useEffect(() => {
        function fetchCategory() {
            fetch('http://localhost:8081/GetAllCategory')
            .then(response => response.json())
            .then(data => {
                setCategory(data.items)
            })
        }

        function fetchBrand() {
            fetch('http://localhost:8081/GetAllBrand')
            .then(response => response.json())
            .then(data => {
                setBrand(data.items)
            })
        }

        fetchCategory()
        fetchBrand()
    }, [])

      const renderMenuItems = (menuItems) => {
        return menuItems.map((item) => {
          if (item.items && item.items.length > 0) {
            return (
              <SubMenu key={item.key} title={item.label}>
                {renderMenuItems(item.items)}
              </SubMenu>
            );
          } else {
            return (
              <Menu.Item key={item.key}>
                {item.icon}
                <span>{item.label}</span>
              </Menu.Item>
            );
          }
        });
      };

    const data = [
        { id: 1, title: 'SHOP TOP' , img: require('../assets/cosmetic_1.jpg'), price: 250000},
        { id: 2, title: 'SHOP SHOES' , img: require('../assets/cosmetic_2.jpg'), price: 150000},
        { id: 3, title: 'SHOP PANTS' , img: require('../assets/cosmetic_3.jpg'), price: 550000},
        { id: 4, title: 'SHOP JACKET' , img: require('../assets/cosmetic_4.jpg'), price: 1000000},
        { id: 5, title: 'SHOP JACKET' , img: require('../assets/cosmetic_4.jpg'), price: 1000000},
        // Add more data objects as needed
      ];

    return(
        <div >
            <div className='search-bar'>
                <Select className='item-selector'
                    showSearch
                    style={{
                        width:'90%',
                        color:'black',
                        opacity:1
                    }}
                    placeholder="Search to Select"
                    optionFilterProp="children"
                    maxTagCount={2}
                    filterOption={(input, option) => (option?.label ?? '').includes(input)}
                    filterSort={(optionA, optionB) =>
                    (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                    }
                    options={[
                    {
                        value: '1',
                        label: 'Search to Select',
                    },
                    {
                        value: '2',
                        label: 'Closed',
                    },
                    {
                        value: '3',
                        label: 'Communicated',
                    },
                    {
                        value: '4',
                        label: 'Identified',
                    },
                    {
                        value: '5',
                        label: 'Resolved',
                    },
                    {
                        value: '6',
                        label: 'Cancelled',
                    },
                    ]}
                />
            </div>

            <div class="search-components">

                <div className='filter-section'>

                    <div className="categories-section">
                        <h2>Categories</h2>
                        <Sider
                            style={{
                            background: 'blue',
                            }}
                            width={200}
                        >
                            <Menu
                            mode="inline"
                            defaultSelectedKeys={['1']}
                            defaultOpenKeys={['sub1']}
                            style={{
                                height: '100%',
                                color:'black'
                            }}
                            >
                                {renderMenuItems(dataCategory)}
                            </Menu>          
                        </Sider>
                    </div>

                    <div className="brands-section">
                        <h2>Brands</h2>
                        <Sider
                            style={{
                            background: 'blue',
                            }}
                            width={200}
                        >
                            <Menu
                            mode="inline"
                            defaultSelectedKeys={['1']}
                            defaultOpenKeys={['sub1']}
                            style={{
                                height: '100%',
                                color:'black'
                            }}
                            >
                                {renderMenuItems(dataBrand)}
                            </Menu>          
                        </Sider>
                    </div>

                </div>
                
                <div className="items-section">
                    
                <Row gutter={20} style={{padding:"50px", justifyContent:"center"}}>
                 {data.map((item => 
                    <Col span={5}  xs={24} sm={12} md={8} lg={6} xl={5} key={item.id}>
                    <Link to={ `/details/${item.id}`}  state={{ data: item}} >
                        <Card cover={<img alt="example" src={item.img} />} className="card">
                        {/* <Meta title="Europe Street beat" description="www.instagram.com" /> */}
                            <hr></hr>
                            <span className="product-name">{item.title}</span>
                            <p className="product-detail">Product Detail disini tertera product detail</p>
                            <p className="product-price">Rp. {item.price.toLocaleString('id-ID')}</p>
                        </Card>
                    </Link>
                    </Col>
                    ))}
                </Row>
                </div>
            </div>

        </div>
    );
}

export default CollectionsCatalog