import 'bootstrap/dist/css/bootstrap.min.css';
import React, { useEffect, useState } from 'react';
import { Table } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import '../App.css';
import SearchBar from '../SearchBar/SearchBar';
import Banner from '../components/Banner';
import CarouselComponent from '../components/CarouselComponent';
import CardComponent from '../components/CardComponent';
import Footer from '../components/Footer';

function Home() {

  const[dataHomeProduct, setHomeProduct] = useState([])

  useEffect(()=>{
    function fetchHomeProduct() {

      const params = new URLSearchParams();
      params.append('limit', 4)

      fetch(`http://localhost:8081/GetAllProduct?${params}`)
        .then((response) => response.json())
        .then((data) => {
            setHomeProduct(data)
            console.log(data)
        })
    }

    fetchHomeProduct();
  }, []);

  return (
    <div>
      <Banner/>
      <CarouselComponent/>
      <CardComponent data={dataHomeProduct}/>
      <Footer/>  
    </div>
  );
}

export default Home;
