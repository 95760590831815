import React from 'react';
import ItemDetailComponent from '../components/ItemDetailComponent'
import Banner from '../components/Banner'

function Details() {

  return (
    <div>
      <Banner/>
      <ItemDetailComponent/>
    </div>
  );
}

export default Details;