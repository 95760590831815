import React, {useState} from "react";
import {Card, Col, Row} from 'antd';
import './CardComponent.css'
import { Link} from 'react-router-dom';

function CardComponent(props) {

    const [imageLoaded, setImageLoaded] = useState(false);

    const handleImageLoad = () => {
        setImageLoaded(true);
    };

    const  dataHomeProduct  = props;

    if (!dataHomeProduct || !dataHomeProduct.data || !dataHomeProduct.data.product) {
        // Data is not available yet, show a loading indicator
        return <div>Loading...</div>;
    }

    console.log(dataHomeProduct)

    const products = dataHomeProduct.data.product;

    const data = [
        { id: 1, title: 'LOREAL PARIS' , img: require('../assets/cosmetic_1.jpg'), price: 250000},
        { id: 2, title: 'DERMA ANGEL 123' , img: require('../assets/cosmetic_2.jpg'), price: 150000},
        { id: 3, title: 'SEPHORA 122DX' , img: require('../assets/cosmetic_3.jpg'), price: 550000},
        { id: 4, title: 'DANCOLY' , img: require('../assets/cosmetic_4.jpg'), price: 1000000},
        // Add more data objects as needed
      ];
      

    return(
        <div>
            <Row gutter={20} style={{padding:"50px", justifyContent:"center"}}>
                 {products.map((item => {
                    return (
                    <Col span={5}  xs={24} sm={12} md={8} lg={6} xl={5} key={item.product_id}>
                        <Link to={ `/details/${item.product_id}`}  state={{ data: item}} >
                            <Card cover={<img alt="example" src={require(`../assets/${item.ProductVariant[0].picture}`)} />} className="card" onLoad={handleImageLoad} style={{ display: imageLoaded ? 'block' : 'none' }}>
                            {/* <Meta title="Europe Street beat" description="www.instagram.com" /> */}
                                <hr></hr>
                                <span className="product-name">{item.product_name}</span>
                                <p className="product-detail">{item.details}</p>
                                {/* <p className="product-price">Rp. {item.price.toLocaleString('id-ID')}</p> */}
                            </Card>
                        </Link>
                    </Col>
                    )
                 }))}
            </Row>
        </div>
    );
}

export default CardComponent