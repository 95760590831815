import React from 'react'
import { Carousel } from 'antd';
import './CarouselComponent.css'


function CarouselComponent(){

    const imageFolder = '/images'
    return(
        <div className='carousel-container'>
        <Carousel autoplay>
            <div className="carousel-slide">
                <div className="jumbotron-carousel" style={{backgroundImage:"url('/images/carousel-jumbo-1.jpg')"}}>
                    
                </div>
            </div>
            <div className="carousel-slide">
                <div className="jumbotron-carousel" style={{backgroundImage:"url('/images/carousel-jumbo-2.jpg')"}}>
                    <div className='jumbotron-content'>
                        <h1 className="display-4 header-font">50% off on all Lipsticks</h1>
                    </div>
                </div>
            </div>
            <div className="carousel-slide">
                <div className="jumbotron-carousel" style={{backgroundImage:"url('/images/carousel-jumbo-3.jpg')"}}>
                    <div className='jumbotron-content'>
                        <h1 className="display-4 header-font">50% off on all Face Cream</h1>
                    </div>
                </div>
            </div>
        </Carousel>
     </div>
    );
}

export default CarouselComponent